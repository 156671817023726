import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./scenes/Home/Home";
import About from "./scenes/About/About"
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Blogs from "./scenes/Blogs/Blogs";
import ProductDetail from "./scenes/ProductDetail/ProductDetail";
import BlogDetail from "./scenes/BlogDetail/BlogDetail";
import OurBrands from "./scenes/OurBrands/OurBrands";
import Product from "./scenes/Products/Product";
import Services from "./scenes/Services/Services";
import BrandDetail from "./scenes/BrandDetail/BrandDetail";
import Contact from "./scenes/Contact/Contact";
import Career from "./scenes/Career/Career";
import ScrollToTop from "./ScrollToTop";
import ThankYouPage from "./components/ThankYouPage/ThankYouPage";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import Ppa from "./scenes/Ppa/Ppa";
import Location from "./scenes/Location/Location";
import Faq from "./scenes/Faq/Faq";
import BecamePartner from "./scenes/BecamePartner/BecamePartner";
import Axitec from "./scenes/LandingPages/Axitec";
import Livguard from "./scenes/LandingPages/Livguard";
import KusumYojana from "./scenes/KusumYojana/KusumYojana";
import SearchResults from "./scenes/SearchResults/SearchResults";
import Disclaimer from "./scenes/Disclaimer/Disclaimer";
import Battery from "./scenes/Battery/Battery"

function App() {


  return (
    <>
      <BrowserRouter>
        <Header/>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/our-company" element={<About />} />
          <Route exact path="/product/:slug" element={<Product />} />
          <Route exact path="/product/battery/:slug" element={<Battery />} />
          <Route exact path="/product-detail/:slug" element={<ProductDetail />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/blog-detail/:slug" element={<BlogDetail />} />
          <Route exact path="/partners" element={<OurBrands />} />
          <Route exact path="/sectors" element={<Services />} />
          <Route exact path="/brand-detail/:slug" element={<BrandDetail />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/thank-you" element={<ThankYouPage />} />
          <Route exact path="/ppa" element={<Ppa />} />
          <Route exact path="/location" element={<Location />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/become-partner" element={<BecamePartner />} />
          <Route exact path="/landingpage/axitec" element={<Axitec/>} />
          <Route exact path="/landingpage/livguard" element={<Livguard/>} />
          <Route exact path="/kusum-yojana" element={<KusumYojana/>} />
          <Route exact path="/disclaimer" element={<Disclaimer/>} />
          <Route exact path="/search" element={<SearchResults />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
