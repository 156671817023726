import React from 'react';
import WhatsAppChat from "../../assets/whatsapp_logo_green.png"

const WhatsAppChatButton = () => {
  return (
    <a
      aria-label="Chat on WhatsApp"
      target='_blank'
      href="https://api.whatsapp.com/send/?phone=%2B917733888175&text=I%27m+interested+in+your+services.&type=phone_number&app_absent=0"
      style={{
        position: 'fixed',
        bottom: '32px',
        right: '32px',
        height: '40px',
        width: '40px',
        zIndex: '9999999',
        display: 'block',
      }}
    >
      <img
        style={{ height: 'auto', width: '100%' }}
        alt="Chat on WhatsApp"
        src={WhatsAppChat}
      />
    </a>
  );
};

export default WhatsAppChatButton;