import React from 'react'
import "./AboutSection.css";

import Img2 from "../../assets/home/engineers-checking-solar-panels-holding-bulb-solar-panel-concepts-clean 1.webp";
import Img1 from "../../assets/home/bulb.png";

import SmallTitle from '../SmallTitle/SmallTitle';
import { Link } from 'react-router-dom';



const AboutSection = () => {
  return (
    <div className="c-container">
      <section className='about-section'>
        <SmallTitle desc="Leading Solar Panel Distributor in India" />
        <div className="big-title">
          <div className="big-title-content">
            <h1 className='big-title-heading'>Saving Energy at Minimal Cost</h1>
          </div>
          <div className="big-title-img">
            <img src={Img1} alt="lightbulb" />
          </div>
        </div>
        <div className="about-section-bottom about-section-bottom-padding">
          <div className="about-section-bottom-img">
            <img src={Img2} alt="representing solar energy concepts" />
          </div>
          <div className="about-section-bottom-content">
            <p className='about-section-bottom-desc'>Leading the way in India’s sector, Kesrinandan Ventures LLP- the leading solar system distributor in India offers outstanding PPC services, Rooftop solar solutions, Cable wires, Solar battery , inverters and so on. Since our founding in 2011, Kesrinandan has come to represent premium, reasonably priced sustainable energy solutions that are powered by the newest technological advancements available.</p>
            <p className='about-section-bottom-desc'>We take immense pride in working with a wide variety of clients, including SMEs in India, government agencies, commercial businesses, educational institutions, and industrial sectors. Our impact is seen throughout India, even though our main office is located in Kota, Rajasthan. We serve clients and stakeholders throughout the nation. Our goal is to build more sustainable and ecologically friendly communities, promote energy conservation, and build inclusive communities by utilizing the enormous potential of sunlight and renewable energy sources.
            </p>
            <p className='about-section-bottom-desc'>Our solutions are created with great care to fulfill the unique demands of the business, commercial and industrial domains, guaranteeing maximum effectiveness and efficiency in each environment.
            </p>
            <Link className="common-btn about-btn" to={"/contact"}>
              Get In Touch
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutSection