import React, { useState, useEffect } from 'react';
import "../BrandDetail/BrandDetail.css"
import { useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { Link } from 'react-router-dom';

import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';

import DefaultImg from "../../assets/brand-banner.png";

const BrandDetail = () => {

    const [data, setData] = useState(null);
    console.log(data)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug } = useParams()


    const defaultImage = DefaultImg;

    const imageSrc = defaultImage;

    const formatTitleFromSlug = (slug) => {
        const titleMap = {
            'e-rickshaw-battery': 'E-rickshaw Battery',
            'solar-battery': 'Solar Battery',
        };

        return titleMap[slug] || slug.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };



    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/batteries?type=${slug}`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (!result?.data) {
                    throw new Error('Invalid slug');
                }
                setData(result?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (error) {
        return <NotFoundPage />;
    }

    return (
        <>
            <Helmet>
                <title>
                    {slug === 'solar-battery'
                        ? 'Solar Batteries for Industrial & Commercial Use - Kesrinandan Ventures'
                        : slug === 'e-rickshaw-battery'
                            ? 'E Rickshaw Batteries for Long-Lasting Power - Kesrinandan Ventures'
                            : data?.meta_title || 'Default Title'}
                </title>
                <meta
                    name="description"
                    content={
                        slug === 'solar-battery'
                            ? 'Get India\'s top-rated solar batteries for industrial & commercial that specially designed for use in solar systems or solar applications. Visit Kesrinandan Ventures and buy Solar battery online.'
                            : slug === 'e-rickshaw-battery'
                                ? 'Get E Rickshaw batteries with advanced tech for efficient, high-mileage performance. Power up your ride with e rickshaw battery provide by Kesrinandan Ventures.'
                                : data?.meta_description || 'Default description'
                    }
                />
                <link rel="canonical" href={`https://kesrinandan.com/product/battery/${slug}`} />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://kesrinandan.com/product/battery/${slug}`} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content={
                        slug === 'solar-battery'
                            ? 'Solar Batteries for Industrial & Commercial Use - Kesrinandan Ventures'
                            : slug === 'e-rickshaw-battery'
                                ? 'E Rickshaw Batteries for Long-Lasting Power - Kesrinandan Ventures'
                                : data?.meta_title || 'Default Title'
                    }
                />
                <meta
                    property="og:description"
                    content={
                        slug === 'solar-battery'
                            ? 'Get India\'s top-rated solar batteries for industrial & commercial that specially designed for use in solar systems or solar applications. Visit Kesrinandan Ventures and buy Solar battery online.'
                            : slug === 'e-rickshaw-battery'
                                ? 'Get E Rickshaw batteries with advanced tech for efficient, high-mileage performance. Power up your ride with e rickshaw battery provide by Kesrinandan Ventures.'
                                : data?.meta_description || 'Default description'
                    }
                />
                <meta property="og:image" content="https://kesrinandan.com/static/media/brand-detail-banner.9346f140c12dd52ee73d.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content={`https://kesrinandan.com/product/battery/${slug}`} />
                <meta
                    name="twitter:title"
                    content={
                        slug === 'solar-battery'
                            ? 'Solar Batteries for Industrial & Commercial Use - Kesrinandan Ventures'
                            : slug === 'e-rickshaw-battery'
                                ? 'E Rickshaw Batteries for Long-Lasting Power - Kesrinandan Ventures'
                                : data?.meta_title || 'Default Title'
                    }
                />
                <meta
                    name="twitter:description"
                    content={
                        slug === 'solar-battery'
                            ? 'Get India\'s top-rated solar batteries for industrial & commercial that specially designed for use in solar systems or solar applications. Visit Kesrinandan Ventures and buy Solar battery online.'
                            : slug === 'e-rickshaw-battery'
                                ? 'Get E Rickshaw batteries with advanced tech for efficient, high-mileage performance. Power up your ride with e rickshaw battery provide by Kesrinandan Ventures.'
                                : data?.meta_description || 'Default description'
                    }
                />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/brand-detail-banner.9346f140c12dd52ee73d.webp" />
            </Helmet>


            <section className="brands-detail-banner">
                <div className="brand-detail-img">
                    <img src={imageSrc} alt={`${slug}`} />
                </div>
                <div className="custom-text custom-text-about">
                    <h1 className='custom-title' style={{ textTransform: "capitalize" }}>
                        {slug && slug.replace(/[-_]/g, ' ').replace(/\s+/g, ' ').trim()}
                        {slug && <span className='color-dot'>.</span>}
                    </h1>
                    <Breadcrumb paths={[
                        { label: 'Home', url: '/' },
                        { label: `${slug}`, url: `/brand-detail/${slug}` }
                    ]} />
                </div>
            </section>

            <section className="brand-detail-section">

                {loading ? <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                /> : (
                    <>
                        <div>
                            <div className="brand-logo">
                                <h2>
                                    {slug === 'e-rickshaw-battery' && 'Eastman E-Rickshaw Batteries'}
                                    {slug === 'solar-battery' && 'Eastman Solar Batteries'}
                                    {(!slug || (slug !== 'e-rickshaw-battery' && slug !== 'solar-battery')) && 'Default Title'}
                                </h2>
                            </div>
                            <div className="brands-desc">
                                <div>
                                    {slug === 'e-rickshaw-battery' && (
                                        <div>
                                            Eastman is a leader in e-rickshaw battery technology, known for innovation, reliability, and a strong delivery record. With a focus on sustainability and advanced engineering, Eastman provides high-performance e-rickshaw batteries for efficient and eco-friendly transportation.
                                        </div>
                                    )}
                                    {slug === 'solar-battery' && (
                                        <div>
                                            Eastman Solar is a solar energy leader that is renowned for innovation, reliability, and a solid delivery track record. Eastman is a global distributor of high-quality solar batteries and products with a focus on sustainability and cutting-edge technology.
                                        </div>
                                    )}
                                    {(!slug || (slug !== 'e-rickshaw-battery' && slug !== 'solar-battery')) && (
                                        <div>
                                            At Kesrinandan, we realize the increasing demand for companies to go for eco-friendly techniques and, at the same time, obtain the maximum value for money. That's why we designed an online platform to facilitate the purchase of high-quality commercial solar panels that are easy to shop for.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='brand-products'>{
                            data?.map((el) => (
                                <div className="products-item" key={el?.id}>
                                    <Link to={`/product-detail/${el?.slug}`}>
                                        <div className="products-item-img">
                                            <img src={el?.image} alt={el?.title} />
                                        </div>
                                        <h5>{el?.title}</h5>
                                    </Link>
                                </div>
                            ))
                        }</div>
                    </>
                )}
            </section>


        </>
    )
}

export default BrandDetail